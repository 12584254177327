export interface AppCommonSupplement {
  id: string;
  organization_id: string;
  content: {
    name: string;
    description: string;
  };
}

export class AppCommonSupplement implements AppCommonSupplement {
  id: string;
  organization_id: string;
  content: {
    name: string;
    description: string;
  };

  constructor() {
    this.id = "";
    this.organization_id = "";
    this.content = {
      name: "",
      description: "",
    };
  }
}